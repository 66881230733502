// [series][0] 计划用时 [series][1] 实际用时

export default [{
        id: 2,
        name: '防雷检测',
        department: '住建部',
        plan: '3天',
        status: '完成',
        series: [{
                start: '2018/01/05',
                end: '2018/01/07',
            },
            {
                start: '2018,01/06',
                end: '2018/01/17',
                options: {
                    color: '#14C53E',
                },
            },
        ],
    },
    {
        id: 3,
        name: '消防检测',
        department: '住建部',
        plan: '3天',
        status: '完成',
        series: [{
                start: '2018/01/06',
                end: '2018/01/08',
            },
            {
                start: '2018/01/06',
                end: '2018/01/15',
                options: {
                    color: '#14C53E',
                },
            },
        ],
    },
    {
        id: 4,
        name: '规划检测',
        department: '住建部',
        plan: '4天',
        status: '进行中',
        series: [{
                start: '2018/01/08',
                end: '2018/01/010',
            },
            {
                start: '2018/01/08',
                end: '2018/01/12',
                options: {
                    color: '#14C53E',
                },
            },
        ],
    },
    {
        id: 5,
        name: '土地及不动产测量',
        department: '住建部',
        plan: '4天',
        status: '进行中',
        series: [{
                start: '2018/01/11',
                end: '2018/01/13',
            },
            {
                start: '2018/01/11',
                end: '2018/01/14',
                options: {
                    color: '#14C53E',
                },
            },
        ],
    },
    {
        id: 6,
        name: '地下管线测量',
        department: '住建部',
        plan: '4天',
        status: '进行中',
        series: [{
                start: '2018/01/05',
                end: '2018/01/08',
            },
            {
                start: '2018/01/05',
                end: '2018/01/10',
                options: {
                    color: '#14C53E',
                },
            },
        ],
    },
    {
        id: 7,
        name: '房屋测量',
        department: '住建部',
        plan: '4天',
        status: '未开始',
        series: [
            { start: '2018/01/15', end: '2018/01/30' },
            {
                start: '2018/01/15',
                end: '2018/02/30',
                options: {
                    color: '#14C53E',
                },
            },
        ],
    },
    {
        id: 8,
        name: '专享验收',
        department: '管委会',
        plan: '4天',
        status: '未开始',
        series: [
            { start: '2018/01/01', end: '2018/01/11' },
            {
                start: '2018/01/01',
                end: '2018/01/11',
                options: {
                    color: '#14C53E',
                },
            },
        ],
    },
    {
        id: 8,
        name: '联合验收',
        department: '住建部',
        plan: '4天',
        status: '未开始',
        series: [
            { start: '2018/01/09', end: '2018/01/14' },
            {
                start: '2018/01/09',
                end: '2018/01/14',
                options: {
                    color: '#14C53E',
                },
            },
        ],
    },
]