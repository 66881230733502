<template>
  <div class="wrapper">
    <div class="content-box mb16">
      <div class="fl-c-b bb mb16">
        <div class="title">项目名称：2号地块厂房建设项目</div>
        <div class="time">（施工单位：温州博弘电力有限公司）</div>
      </div>
      <div>
        <ul class="info-box fl-box">
          <li>
            <span class="info-type">计划用时</span>
            <span class="info-value">30天</span>
          </li>
          <li>
            <span class="info-type">已用时</span>
            <span class="info-value">12天</span>
          </li>
          <li>
            <span class="info-type">办结用时</span>
            <span class="info-value">29天</span>
          </li>
        </ul>
        <div class="gantt-top clearfix">
          <div class="legend fl">
            <span class="legend-icon blue"> </span>
            计划用时
            <span class="legend-icon green" style="margin-left: 16px"> </span>
            实际用时
          </div>
        </div>
        <div class="gantt-container">
          <div id="ganttChart"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// const $  = require("jquery");
// import $ from  'jquery';
import '@/assets/gantt/css/ganttView.css';
import '@/assets/gantt/css/jquery-ui-1.8.4.css';
import ganttData from '@/assets/gantt/js/data.js';
import '@/assets/gantt/js/ganttView.js';
// import Swiper core and required components
// install Swiper components
export default {
  data() {
    return {};
  },
  components: {},
  methods: {},
  computed: {},
  mounted() {
    $('#ganttChart').ganttView({
      // showWeekends: true,
      data: ganttData,
      behavior: {
        onClick: function (data) {
          var msg = 'click事件:' + JSON.stringify(data);
          console.log(msg);
        },
        onResize: function (data) {
          var msg = 'resize事件:' + JSON.stringify(data);
          console.log(msg);
        },
        onDrag: function (data) {
          // console.log(data);
          var msg = 'drag事件:' + JSON.stringify(data);
          console.log(msg);
        },
      },
    });
  },
  created() {
    // this.queryTable.search();
  },
};
</script>


<style lang='scss' scoped>
.content-box {
  background-color: #fff;
  padding: 0 20px 26px;
  margin: 16px;
}

.title {
  line-height: 74px;
  font-size: 26px;
  color: #333333;
  font-weight: 600;
  padding-left: 14px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 28px;
    left: 0;
    width: 4px;
    height: 20px;
    background: #4393f8;
    border-radius: 3px;
  }
}

.time {
  font-size: 14px;
  color: #666666;
}
.mb16 {
  margin-bottom: 16px;
}
.info-box {
  padding: 10px 16px;
  background: #ecf5ff;
  border-radius: 4px;
  margin: 16px 0 20px 0;
}

.info-box > li {
  margin-right: 40px;
}

.info-type {
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  vertical-align: bottom;
}

.info-value {
  font-size: 20px;
  color: #0e459c;
  line-height: 20px;
  font-weight: 600;
  margin-left: 5px;
}

.gantt-top {
  margin-bottom: 16px;
}

.legend {
  font-size: 14px;
  color: #666666;
  line-height: 14px;
}

.legend-icon {
  display: inline-block;
  width: 12px;
  height: 6px;
  position: relative;
  top: -1px;
}

.legend-icon.blue {
  background: #255ada;
}

.legend-icon.green {
  background: #14c53e;
}
table {
  width: 350px;
  height: 323px;
  font-size: 14px;
}

table,
table tr th,
table tr td {
  border: 1px solid #ddd;
  margin: 0;
  /* 合并边框 */
  border-collapse: collapse;
  text-align: center;
  vertical-align: middle;
}

th {
  height: 60px;
  background-color: #f3f3f5;
  font-weight: 600;
}

td {
  width: 70px;
  height: 48px;
}
</style>